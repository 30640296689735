.react-calendar {
    width: 90%;
    max-width: 100%;
    background: white;
    /* border: 1px solid #a0a096; */
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  @media screen and (max-width: 600px) {
    .react-calendar {
      width: 100vw;
      padding-top: 10px;
    }
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    /* outline: 1px solid #063970; */
    border: 1px solid #063970;
  }
  @media screen and (max-width: 600px) {
    .react-calendar button {
        border: 0.75px solid #063970;
    }
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    border: none;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2em;
    color: #B21414;
    text-transform: uppercase;
    border: none;
  }
  @media screen and (max-width: 600px) {
    .react-calendar__navigation button {
        font-size: 1.5em;
    }
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: rgba(6, 57, 112, 0.05);
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1.25em;
    color: #063970;
    padding-bottom: .75rem;
    border: "none";
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: 0.75em;
    font-weight: bold;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #063970;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #d4d4d4;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 600 ;
    text-align: left;
    text-transform: capitalize;
    padding: 0.45em 0.45em;
    background: none;
    height: 6rem;
    color: #063970;
  }
  @media screen and (max-width: 600px) {
    .react-calendar__tile {
        height: 5rem;
    }
  }  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    /* background: rgba(66, 166, 41, 0.5); */
    outline: 4px solid #B21414;
    outline-offset: -3px;
    color: #B21414;
    text-transform: capitalize;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    /* background: rgba(66, 166, 41, 0.25); */
    /* outline: 6px solid #B21414;
    outline-offset: -5px; */
  }
  .react-calendar__tile--hasActive {
    /* background: #063970; */
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #e6e6e6;
  }
  .react-calendar__tile--active {
    /* background: #063970;
    color: white; */
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    /* background: #063970; */
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  .highlight {
    color: #063970;
  }
  .highlight--active {
    color: #e6e6e6;
  }
  .calendarMarkedDate {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    /* padding-right: 10px; */
    /* background-color: burlywood; */
  }
  .calendarMarkedDateContainer {
    width: 100%;
    height: 100%;
    margin-left: -7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .react-calendar__viewContainer {
    border: "none";
  }
  